<script setup lang="ts">
  import Button from '~/components/design-system/button.vue';
  import { useSiteStore } from '~/stores/site.store';
  import { inject } from 'vue';
  import type { IAddLimitRequest as IAddLimitPayload } from '~/interfaces/dto/player/request';
  import { useAuthService } from '~/composables/services/useAuthService';

  const site = useSiteStore();
  const $authService = useAuthService();
  const limit  = inject('coolOffLimit');
  const period = inject('coolOffPeriod')

  const previous = () => {
    site.deactivateModal();
  }
  
</script>
<template>
  <div class="px-4 flex flex-col gap-2">
    <p>{{ $t('cooling-off-period-body-1') }}</p>
      <p>{{ $t('your-acc-will-be-locked-for') }}
      <strong>
        {{ limit + ' ' }} {{ $t('period') }}
      </strong>
      ?</p>
    <p>{{ $t('cooling-off-period-body-2') }}</p>
  </div>
  <div class="flex p-4 bg-layer-1 w-full">
    <Button
      type="primary"
      class="w-full justify-center"
      @click=""
    >
      {{ $t('confirm') }}
    </Button>
    <Button type="secondary" class="w-full justify-center" @click="site.deactivateModal()">
      {{ $t('cancel') }}
    </Button>
  </div>
</template>
